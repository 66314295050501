/*global  ajax_object*/
// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
// Import gsap
// import {
//   gsap,
//   ScrollTrigger,
//   ScrollToPlugin,
//   MotionPathPlugin,
// } from 'gsap/all';
//
// gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, MotionPathPlugin);
/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js'; import '../blocks/elementor/map/index.js'; import '../blocks/elementor/pages-slider/index.js'; import '../blocks/elementor/poa-login/index.js'; import '../blocks/elementor/table/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  $.fn.isInViewport = function () {
    let elementTop = $(this).offset().top;
    let elementBottom = elementTop + $(this).outerHeight();
    let viewportTop = $(window).scrollTop();
    let viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  };
  $(window).on('scroll', function () {
    $('.gsap__wrapper').each(function () {
      let maxHeight = $('.svg-line').height();
      if ($(this).isInViewport()) {
        let diff =
          $(window).scrollTop() + $(window).height() - $(this).offset().top;
        if (diff < maxHeight / 0.6) {
          $(this).height(diff * 0.7);
        }
      }
    });
  });

  // $('.filter-item').on('click', function (e) {
  //   e.preventDefault();
  //
  //   // Manage active filter class
  //   $('.filter-item').removeClass('active-filter');
  //   $(this).addClass('active-filter');
  //
  //   // Extract post type from the clicked item's href attribute
  //   var postType = $(this).attr('href');
  //   var data = {
  //     action: 'filter_posts_by_post_type',
  //     post_type: postType,
  //     paged: 1, // Always reset to page 1 when a new filter is clicked
  //   };
  //
  //   // Hide the posts container and show the preloader
  //   $('#posts-container').fadeOut();
  //
  //   $.ajax({
  //     type: 'POST',
  //     url: ajax_object.ajax_url, // Ensure ajax_object is localized in your script
  //     data: data,
  //     beforeSend: function () {
  //       $('.preloader-resources').removeClass('preloader--hidden hide');
  //       $('.resources').addClass('is-loading');
  //     },
  //     success: function (response) {
  //       if (response.success) {
  //         // Update the posts container with the new content
  //         $('#posts-container').html(response.data);
  //         $('#posts-container').fadeIn();
  //
  //         // Hide the preloader
  //         $('.preloader-resources').addClass('preloader--hidden hide');
  //         $('.resources').removeClass('is-loading');
  //       } else {
  //         console.error('No posts found or server-side error.');
  //         $('#posts-container').html('<p>No posts found.</p>').fadeIn();
  //       }
  //     },
  //     error: function (xhr, status, error) {
  //       console.error('AJAX Error:', error);
  //       $('#posts-container')
  //         .html(
  //           '<p>An error occurred while fetching posts. Please try again.</p>'
  //         )
  //         .fadeIn();
  //     },
  //   });
  // });

  // Filter click handler
  $('.resources-filter__list a').on('click', function (e) {
    e.preventDefault();
    const filterType = $(this).attr('href');
    const container = $('#posts-container');
    const preloader = $('.preloader-resources');

    $('.resources-filter__list a').removeClass('active');
    $(this).addClass('active');

    container.html(''); // Clear container

    $.ajax({
      url: ajax_object.ajax_url, // This is provided by WordPress
      type: 'POST',
      data: {
        action: 'filter_posts',
        post_type: filterType,
        paged: 1,
      },
      beforeSend: function () {
        preloader.removeClass('preloader--hidden hide'); // Show preloader
        $('.resources').addClass('is-loading');
      },
      success: function (response) {
        container.html(response); // Load the new posts
        preloader.addClass('preloader--hidden hide'); // Hide preloader
      },
    });
  });

  // Pagination click handler
  $(document).on('click', '.pagination a', function (e) {
    e.preventDefault();
    const link = $(this).attr('href');
    const queryVars = new URLSearchParams(link.split('?')[1]);
    const paged = queryVars.get('paged');
    const filterType = $('.resources-filter__list a.active').attr('href');
    const container = $('#posts-container');
    const preloader = $('.preloader-resources');

    container.html(''); // Clear container

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: {
        action: 'filter_posts',
        post_type: filterType,
        paged: paged,
      },
      beforeSend: function () {
        preloader.removeClass('preloader--hidden hide'); // Show preloader
        $('.resources').addClass('is-loading');
      },
      success: function (response) {
        container.html(response); // Load the new posts
        preloader.addClass('preloader--hidden hide'); // Hide preloader
      },
    });
  });

  // $('#sort-by').on('change', function () {
  //   let postType = $('.active-filter').attr('href');
  //   var sortBy = $(this).val();
  //   var data = {
  //     action: 'sort_posts',
  //     sort_by: sortBy,
  //     post_type: postType,
  //   };
  //
  //   $.ajax({
  //     type: 'POST',
  //     // eslint-disable-next-line no-undef
  //     url: ajax_object.ajax_url,
  //
  //     data: data,
  //     beforeSend: function () {},
  //     success: function (response) {
  //       if (response.success) {
  //         $('#posts-container').html(response.data);
  //       } else {
  //       }
  //     },
  //     error: function (errorThrown) {},
  //   });
  // });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  $('.accord-button').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('active-button');
    // $(this).parent('.left-images').siblings('.tab-content').slideToggle();
    // console.log(
    //   $(this).closest('.left-images').siblings('.tab-content').slideToggle()
    // );
    $(this).closest('.left-images').siblings('.tab-content').slideToggle();
  });
  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 100 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader-resources');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
